import Cookies from 'js-cookie'
import store from '@/store'

const TokenKey = 'isLogged'
const Token = 'token'
const User = 'user'

const getDomainWithoutSubdomain = () => {
  // const parts = hostname.split('.') // Split by '.'
  const { hostname } = window.location // e.g., sub1.sub2.example.com
  if (hostname.startsWith('.')) {
    return hostname.substring(1) // Remove the first character if it's a dot
  }
  return hostname

  // // If it's an IP address or localhost, just return it
  // if (parts.length <= 2 || hostname === 'localhost') {
  //   return hostname
  // }

  // // Return the last two parts (e.g., example.com)
  // if (parts?.length > 3) {
  //   return `admission.${parts.slice(-3).join('.')}`
  // }
  // return `admission.${parts.slice(-3).join('.')}`
}

export function isLogged() {
  return Cookies.get(TokenKey) === '1'
}

export function notHasPermissions() {
  return Cookies.get(TokenKey) === '2'
}

export function setLogged(num) {
  document.cookie = `isLogged=; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  document.cookie = `isLogged=${num}; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  // return Cookies.set(TokenKey, num)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setToken(token) {
  document.cookie = `token=; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  document.cookie = `token=${token}; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  // return Cookies.set(Token, token)
}

export function getToken() {
  return Cookies.get(Token)
}

export function deleteToken() {
  return Cookies.remove(Token)
}

export function Auth(user) {
  document.cookie = `user=; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  document.cookie = `user=${user}; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  // return Cookies.set(User, user)
}

export function getAuth() {
  return Cookies.get(User)
}

export function removeAuth() {
  return Cookies.remove(User)
}

export function resetPermission() {
  store.commit('roles/SET_AUTH_USER_PERMISSIONS', [])
  store.commit('roles/SET_AUTH_USER_ROLES_PERMISSIONS', [])
}
