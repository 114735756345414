import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, TooltipPlugin, VBTooltip, ButtonPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import yearPicker from 'vue-year-picker'
import i18n from '@/libs/i18n'
import VueCookie from 'vue-js-cookie'
import moment from 'moment'
// Vue.use(Directives)

// vuex
import Vuex from 'vuex'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import '@/mixins/mixins'

import VueLazyload from 'vue-lazyload'
import App from './App.vue'
import store from './store'
import router from './router'

export const EventBus = new Vue()
// import Directives from './directive/directive'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
// Vue.use(VueReCaptcha, { siteKey: '6LdRItIpAAAAAKqZvn8G3SA3bYJKQnnHS2wAaFrm' })
Vue.use(VueCookie)

Vue.use(yearPicker)
Vue.use(Vuex)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)
Vue.use(ButtonPlugin)
Vue.directive('b-tooltip', VBTooltip)
// Composition API
Vue.use(VueCompositionAPI)
// Vue.use(VueLazyload)

// or with options
const loadimage = require('./assets/images/loading4.gif')
// errorimage = require('./assets/images/loading1.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: errorimage,
  loading: loadimage,
  attempt: 1,
})

Vue.prototype.moment = moment

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
Vue.config.errorHandler = function (err, vm, info) {
  console.error('Vue Error:', err, info)
}
