import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import admission from './routes/admission'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'apply' } },
    ...admission,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.meta && to.meta.admission) {
    if (!isLoggedIn && !to.meta.redirectIfLoggedIn && to.name !== 'misc-under-maintenance') {
      if (to.meta && to.meta.admission) {
        return next()
      }
      return next({ name: 'auth-login' })
    }
  }
  if (to.query.code) {
    return next({
      name: 'auth-login',
      params: {
        code: to.query.code,
        session_state: to.query.session_state,
        state: to.query.state,
      },
    })
  }

  if (!canNavigate(to) && to.name !== 'misc-under-maintenance') {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  const isLoggedIn = isUserLoggedIn()
  if (to.name === 'auth-login' && isLoggedIn) {
    router.push({ name: 'dashboard' })
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
